import React from 'react';
import styled from 'styled-components';
import {EditorialImage, DfpAd} from '@/components';
import {PageGrid, EditorialPlacement} from '@/homePage/components';
import {useRedux} from './TopContent.shared';
import {useAd} from '@/hooks';

const BRANDED_CONTENT_INDEX = 1;

const TopContent = () => {
  const {hero, placements, heroEntity, onPlacementClick, showAds} = useRedux();
  const {state: adState} = useAd('desktop_home_promo_unit');
  const promoSlotRequestedOrFilled = adState !== 'empty';

  return (
    <PageGrid>
      <TopContent.Hero>
        <EditorialPlacement
          placement={hero}
          variant="homeTopHero"
          onClick={onPlacementClick}
        />
      </TopContent.Hero>
      <TopContent.HeroImageLink href={heroEntity.url} onClick={onPlacementClick}>
        <EditorialImage
          url={heroEntity[heroEntity.type === 'article' ? 'previewImage' : 'posterUrl']}
          playButton={heroEntity.type === 'video'}
          lazy={false}
        />
      </TopContent.HeroImageLink>
      {placements.map((placement, i) => (
        <TopContent.Placement key={`${placement.schema}:${placement.id}`} line={i < 3}>
          {showAds && i === BRANDED_CONTENT_INDEX && promoSlotRequestedOrFilled ?
            <TopContent.PromoContainer>
              <DfpAd
                name={'desktop_home_promo_unit'}
                resizeIframe={true}
                lazy={false}
                refreshIntervalSeconds={null}
              />
            </TopContent.PromoContainer> :
            <EditorialPlacement
              placement={placement}
              variant="homeTopDesktop"
              onClick={onPlacementClick}
            />
          }
        </TopContent.Placement>
      ))}
    </PageGrid>
  );
};

export default React.memo(TopContent);

TopContent.Hero = styled.div`
  grid-column: span 5;
`;

TopContent.HeroImageLink = styled.a`
  grid-column: span 7;
`;

TopContent.PromoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

TopContent.Placement = styled.div`
  grid-column: span 3;
  min-height: 400px;
  position: relative;
  ${p => p.line && `
    &:before {
      content: '';
      position: absolute;
      top: calc(${p.theme.border.standardWidth}/2);
      right: calc(-30px - ${p.theme.border.standardWidth}/2);
      height: 100%;
      min-height: inherit;
      border-right: ${p.theme.border.standardWidth} solid ${p.theme.color.background.on};
    }
  `}
`;
