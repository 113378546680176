import React from 'react';
import {PageGrid} from '@/homePage/components';
import styled from 'styled-components';
import {propTypes, itemComponent} from './ChartItem.shared';
import noop from 'lodash/noop';
import {useEntity} from '@/hooks';

const ChartItem = ({item, rank, onClick = noop}) => {
  const {schema, id} = item;
  const {url} = useEntity(schema, id);
  const ItemComponent = itemComponent(schema);
  return (
    <ChartItem.Row as="a" href={url} onClick={onClick}>
      <ChartItem.Rank>{rank}</ChartItem.Rank>
      <ItemComponent itemId={id} />
    </ChartItem.Row>
  );
};

ChartItem.propTypes = propTypes;

export default React.memo(ChartItem);

ChartItem.Row = styled(PageGrid)`
  color: inherit;
  width: 100%;
  align-items: center;
  grid-auto-rows: minmax(75px, auto);
  border-bottom: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.variant};
  font-size: ${p => p.theme.fontSize.smallHeadline};
  transition: background-color .1s;

  &:hover {
    background: ${p => p.theme.color.background.variant};
  }
`;

ChartItem.Rank = styled.div`
  grid-column: span 1;
  font-size: ${p => p.theme.fontSize.smallHeadline};
  font-weight: 100;
  align-self: flex-start;
  margin-top: 20px;
  text-align: center;
`;
