import {produce} from 'immer';

import {mergeEntities} from '@/util/redux';
import {LOAD_MORE_LOADED, CHART_FILTER_UPDATE_LOADED} from '@/homePage/actions';

export default produce((draft, action) => {
  switch (action.type) {
  case LOAD_MORE_LOADED:
  case CHART_FILTER_UPDATE_LOADED:
    mergeEntities(draft, action.entities);
    break;
  default:
    return draft;
  }
});
