import 'es6-shim/es6-shim';
import 'es7-shim/browser';
import 'whatwg-fetch';

import React from 'react';
import Application from '@/homePage';
import createMiddleware from '@/homePage/logics';
import rootReducer from '@/homePage/reducers';
import {clientRender} from '@/render';

clientRender('home', <Application />, rootReducer, createMiddleware);
