import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {useDeviceType} from '@/hooks';
import {TextLabel} from '@/components';
import {PageGrid, PageGridCenter, PageGridFull} from '@/homePage/components';

const SectionTitle = ({title, subtitle, titleSize, subtitleSize}) => {
  const defaultTitleSize = useDeviceType({mobile: 'xLargeHeadline', desktop: 'xxLargeHeadline'});
  const defaultSubtitleSize = useDeviceType({mobile: 'xSmallReading', desktop: 'smallReading'});
  return (
    <SectionTitle.Container noRowGap={true}>
      <SectionTitle.Title as="h2" size={titleSize || defaultTitleSize}>{title}</SectionTitle.Title>
      <SectionTitle.Subtitle>
        <TextLabel as="h3" color="accent.main" size={subtitleSize || defaultSubtitleSize}>{subtitle}</TextLabel>
      </SectionTitle.Subtitle>
    </SectionTitle.Container>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  titleSize: PropTypes.string,
  subtitleSize: PropTypes.string,
};

export default React.memo(SectionTitle);

SectionTitle.Container = styled(PageGrid)`
  grid-column: grid-start / grid-end;
`;

SectionTitle.Title = styled(PageGridFull)`
  color: ${p => p.theme.color.background.on};
  font-size: ${p => p.theme.fontSize[p.size]};
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  letter-spacing: 2px;
`;

SectionTitle.Subtitle = styled(PageGridCenter)`
  margin-top: ${p => p.theme.space.half};
  text-align: center;

  h3 {
    line-height: ${p => p.theme.lineHeight.reading};
  }
`;
