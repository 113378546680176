import {shallowEqual, useSelector} from 'react-redux';
import get from 'lodash/get';
import {useMixpanelEvent} from '@/hooks';

export const useRedux = () => {
  const placements = useSelector(state => get(state, 'home.latestSection.editorialPlacements', []).map(({content}) => content), shallowEqual);
  const onPlacementClick = useMixpanelEvent('home:latest_item_clicked', {in_top: false});
  const onLoadMoreClick = useMixpanelEvent('home:latest_more_loaded');

  return {
    placements,
    onPlacementClick,
    onLoadMoreClick,
  };
};
