import merge from 'lodash/merge';
import {lightTheme} from './theme';

export const homePageTheme = merge({}, lightTheme, {
  color: {
    success: {
      main: '#178106',
    },
  },
  fontSize: {
    xSmallReading: '.75rem',
    smallReading: '.875rem',
    xSmallHeadline: '1.313rem',
  },
  space: {
    xxxLarge: '4.5rem',
  },
});
