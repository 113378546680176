import {useSelector, shallowEqual} from 'react-redux';
import {useEntity, useMixpanelEvent} from '@/hooks';

export const useRedux = () => {
  const [hero, ...placements] = useSelector(
    state => state.home.topSection.editorialPlacements.map(({content}) => content),
    shallowEqual
  );
  const showAds = useSelector(state => state.session.showAds);
  const heroEntity = useEntity(hero.schema, hero.id);
  const onPlacementClick = useMixpanelEvent('home:top_item_clicked');
  return {
    hero,
    placements,
    heroEntity,
    onPlacementClick,
    showAds,
  };
};
