import React, {useEffect} from 'react';
import {TopContent, Charts, Videos, Latest, Community} from '@/homePage/components';
import {PageHeader, PageFooter, SolidarityBanner, LeaderboardOrMarquee} from '@/components';
import {useMixpanelEvent, useAd} from '@/hooks';
import styled, {ThemeProvider} from 'styled-components';
import DfpAd from '@/components/DfpAd';
import {surfaceTheme} from '@/style/theme';
import {zIndex} from '@/style';
import {homePageTheme} from '@/style/homePageTheme';

const HomeContent = () => {
  const onLoad = useMixpanelEvent('home:load', {counts_as_page_load: true});
  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const {state: skinState, custom: {backgroundColor: skinBackgroundColor}} = useAd('desktop_home_skin');

  const {state: leaderboardState, metadata: leaderboardMetadata} = useAd('desktop_home_leaderboard');
  const marquee = leaderboardState === 'filled' &&
    leaderboardMetadata.width === 4 &&
    leaderboardMetadata.height === 1;

  return (
    <ThemeProvider theme={homePageTheme}>
      <HomeContent.Container>
        {!marquee &&
          <>
            <PageHeader />
            <SolidarityBanner />
            <HomeContent.SkinContainer backgroundColor={skinBackgroundColor}>
              <HomeContent.Skin>
                <DfpAd name="desktop_home_skin" lazy={false} refreshIntervalSeconds={null} />
              </HomeContent.Skin>
            </HomeContent.SkinContainer>
          </>
        }
        <LeaderboardOrMarquee
          name="desktop_home_leaderboard"
          bottomMargin={skinState === 'filled'}
        />
        {marquee &&
          <>
            <PageHeader />
            <SolidarityBanner />
          </>
        }
        <HomeContent.CenteredFlexColumn>
          <HomeContent.Section noBottomPadding={true} id="featured-stories">
            <TopContent />
          </HomeContent.Section>
          <HomeContent.Section id="top-songs">
            <Charts />
          </HomeContent.Section>
        </HomeContent.CenteredFlexColumn>
        <ThemeProvider theme={surfaceTheme}>
          <HomeContent.Section id="videos">
            <Videos />
          </HomeContent.Section>
        </ThemeProvider>
        <HomeContent.Section noBottomPadding={true}>
          <Latest />
        </HomeContent.Section>
        <HomeContent.Section id="community">
          <Community />
        </HomeContent.Section>
        <PageFooter />
      </HomeContent.Container>
    </ThemeProvider>
  );
};

export default HomeContent;

HomeContent.Container = styled.div`
  position: relative;
`;

HomeContent.CenteredFlexColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

HomeContent.SkinContainer = styled.div`
  ${zIndex('skin')}
  position: absolute;
  height: calc(100% - ${p => p.theme.header.heightWithSubnavigation});
  width: 100%;
  top: ${p => p.theme.header.heightWithSubnavigation};
  ${p => p.backgroundColor ? `background-color: ${p.backgroundColor};` : ''}
  display: flex;
  flex-direction: column;
  align-items: center;
`;

HomeContent.Skin = styled.div`
  position: sticky;
  top: 0;
`;

HomeContent.Section = styled.div`
  padding: ${p => p.theme.space.xxxLarge} 60px;
  background-color: ${p => p.theme.color.background.main};
  ${p => p.noBottomPadding ? 'padding-bottom: 0px;' : ''}
`;
