import {createLogic} from 'redux-logic';
import {CHART_FILTER_UPDATED, chartFilterUpdateLoaded, chartFilterUpdateFailed} from '@/homePage/actions';
import {getChartItems} from '@/api';
import bugsnag from '@/bugsnag';

const chartsFilterLogic = createLogic({
  type: CHART_FILTER_UPDATED,
  latest: true,
  validate: ({getState, action}, allow, reject) => {
    const {home: {chartSection: {timePeriod, chartType, chartGenre}}} = getState();
    if (
      action.updatedFilters.timePeriod === timePeriod &&
        action.updatedFilters.chartType === chartType &&
        action.updatedFilters.chartGenre === chartGenre
    ) {
      reject();
    } else {
      const updatedFilters = {
        ...action.updatedFilters,
        chartGenre: action.updatedFilters.chartType === 'songs' ? action.updatedFilters.chartGenre : 'all',
      };
      allow({
        ...action,
        updatedFilters,
      });
    }
  },
  process: ({getState, action: {updatedFilters}}, dispatch, done) => {
    const {home: {chartSection: {timePeriod, chartType, chartGenre, perPage}}} = getState();
    getChartItems({timePeriod, chartType, chartGenre, perPage, ...updatedFilters}).
      then(response => dispatch(chartFilterUpdateLoaded(response))).
      catch((e) => {
        bugsnag.notify(e);
        dispatch(chartFilterUpdateFailed());
      }).
      then(done);
  },
});

export default [chartsFilterLogic];
