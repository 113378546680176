import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import camelCase from 'lodash/camelCase';
import Verified from '@/svg/verified.svg';
import News from '@/svg/news.svg';
import Deconstructed from '@/svg/deconstructed.svg';
import Framework from '@/svg/framework.svg';
import Centerstage from '@/svg/centerstage.svg';
import Irl from '@/svg/irl.svg';
import ForTheRecord from '@/svg/forTheRecord.svg';
import OpenMic from '@/svg/openMic.svg';
import GeniusTest from '@/svg/geniusTest.svg';
import GeniusLevel from '@/svg/geniusLevel.svg';
import TheCosign from '@/svg/theCosign.svg';
import BetweenTheLines from '@/svg/betweenTheLines.svg';
import noop from 'lodash/noop';

const VideoListLogo = ({slug, url, onClick = noop}) => {
  const camelCaseName = camelCase((slug));
  const name = camelCaseName.charAt(0).toUpperCase() + camelCaseName.slice(1);
  const Logo = svgByName[name];
  return (
    <VideoListLogo.Link title={name} href={url} onClick={onClick}>
      {Logo && <Logo />}
    </VideoListLogo.Link>
  );
};

VideoListLogo.propTypes = {
  url: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

const svgByName = {
  Verified,
  News,
  Deconstructed,
  Framework,
  Centerstage,
  Irl,
  ForTheRecord,
  OpenMic,
  GeniusTest,
  GeniusLevel,
  TheCosign,
  BetweenTheLines,
};

export default React.memo(VideoListLogo);

VideoListLogo.Link = styled.a`
  display: block;
  border-top: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.on};
  padding-top: ${p => p.theme.space.full};
  margin-bottom: ${p => p.theme.space.half};
  height: 2.5rem;
  position: relative;

  svg {
    fill: ${p => p.theme.color.background.on};
    display: block;
    height: 100%;
    max-width: 100%;
  }
`;
