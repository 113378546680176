import React from 'react';
import {SizedImage, PlayButtonOverlay, LoadMoreButton} from '@/components';
import {PageGrid, PageGridCenter, EditorialPlacement, SectionTitle} from '@/homePage/components';
import styled from 'styled-components';
import {useRedux} from './Videos.shared';
import {useTranslation} from 'react-i18next';

const Videos = () => {
  const {heroId, heroImageUrl, heroUrl, videoListIds, onPlacementClick, onLoadMoreClick} = useRedux();
  const {t} = useTranslation();
  return (
    <PageGrid>
      <SectionTitle
        title={t('videos.title')}
        subtitle={t('videos.subtitle')}
      />
      <Videos.HeroImageLink href={heroUrl} onClick={onPlacementClick}>
        <PlayButtonOverlay>
          <SizedImage
            src={heroImageUrl}
            aspectRatio={16 / 9}
            fitHeightAndRepeat={true} />
        </PlayButtonOverlay>
      </Videos.HeroImageLink>
      <Videos.Hero>
        <EditorialPlacement
          videoListId={heroId}
          variant="watchHeroDesktop"
          onClick={onPlacementClick}
        />
      </Videos.Hero>
      {videoListIds.map(id => (
        <Videos.Placement key={id}>
          <EditorialPlacement
            videoListId={id}
            variant="watch"
            onClick={onPlacementClick}
          />
        </Videos.Placement>
      ))}
      <LoadMoreButton
        path="home.videoSection"
        container={Videos.LoadMore}
        onClick={onLoadMoreClick}
      />
    </PageGrid>
  );
};

export default Videos;

Videos.HeroImageLink = styled.a`
  grid-column: span 9;
`;

Videos.Hero = styled.div`
  grid-column: span 3;
`;

Videos.Placement = styled.div`
  grid-column: span 3;
`;

Videos.LoadMore = styled(PageGridCenter)`
  text-align: center;
`;
