import React from 'react';
import styled from 'styled-components';
import {DfpAd} from '@/components';
import {propTypes} from './ChartHeader.shared';
import {ChartsFilter, PageGridFull} from '@/homePage/components';

const ChartHeader = ({title, adName}) => (
  <ChartHeader.Container>
    <ChartHeader.SectionTitle>
      {title}
    </ChartHeader.SectionTitle>
    <ChartHeader.AdContainer>
      <DfpAd
        name={adName}
        lazy={false}
        refreshIntervalSeconds={null}
      />
    </ChartHeader.AdContainer>
    <ChartHeader.Filter>
      <ChartsFilter />
    </ChartHeader.Filter>
  </ChartHeader.Container>
);

export default React.memo(ChartHeader);

ChartHeader.propTypes = propTypes;

ChartHeader.Container = styled(PageGridFull)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`;

ChartHeader.SectionTitle = styled.h2`
  color: ${p => p.theme.color.background.on};
  font-size: ${p => p.theme.fontSize.xxLargeHeadline};
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 2px;
  text-align: left;
`;

ChartHeader.AdContainer = styled.div`
  padding-left: 2.25rem;
`;

ChartHeader.Filter = styled.div`
  margin-left: auto;
`;
