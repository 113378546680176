import React from 'react';
import {SquareButton, TextLabel, LoadMoreButton} from '@/components';
import {PageGrid, PageGridCenter, PageGridFull, SectionTitle, CommunityRanking} from '@/homePage/components';
import styled from 'styled-components';
import {useRedux} from './Community.shared';
import {AppConfig} from '@/config';
import {useTranslation} from 'react-i18next';

const Community = () => {
  const {t} = useTranslation();
  const {leaderboard, loggedIn, onRankingClick, onLearnMoreClick, onSignupClick, onGetInvolvedClick} = useRedux();
  return (
    <PageGrid>
      <SectionTitle
        title={t('community.title')}
        subtitle={t('community.subtitle')}
      />
      <PageGridFull>
        <Community.Buttons>
          <Community.Join>
            {loggedIn ?
              <SquareButton as="a" href={AppConfig.getInvolvedPageUrl} onClick={onGetInvolvedClick}>{t('community.get_involved')}</SquareButton> :
              <SquareButton as="a" href="/signup" onClick={onSignupClick}>{t('community.join')}</SquareButton>
            }
          </Community.Join>
          <SquareButton as="a" href="/contributor_guidelines" onClick={onLearnMoreClick}>{t('community.learn')}</SquareButton>
        </Community.Buttons>
      </PageGridFull>
      <PageGridFull>
        <Community.ChartHeader>
          <Community.Rank>
            <TextLabel>{t('community.chart.headers.rank')}</TextLabel>
          </Community.Rank>
          <Community.User>
            <TextLabel>{t('community.chart.headers.scholar')}</TextLabel>
          </Community.User>
          <Community.Iq>
            <TextLabel>{t('community.chart.headers.iq')}</TextLabel>
          </Community.Iq>
        </Community.ChartHeader>
        {leaderboard.map(({user: userId, attributionValue: iq}, i) =>
          <CommunityRanking
            key={userId}
            onClick={onRankingClick}
            rank={i + 1}
            iq={iq}
            userId={userId}
          />
        )}
      </PageGridFull>
      <LoadMoreButton path="home.communitySection" container={Community.LoadMore} />
    </PageGrid>
  );
};

export default React.memo(Community);

Community.Buttons = styled.div`
  text-align: center;

  a {
    width: 240px;
    padding: ${p => p.theme.space.small};
  }
`;

Community.Join = styled.span`
  margin-right: ${p => p.theme.space.xxLarge};
`;

Community.ChartHeader = styled(PageGrid)`
  padding-bottom: ${p => p.theme.space.quarter};
  border-bottom: ${p => p.theme.border.standardWidth} solid ${p => p.theme.color.background.variant};
  align-items: end;
`;

Community.Rank = styled.div`
  grid-column: span 1;
`;

Community.User = styled.div`
  grid-column: span 9;
`;

Community.Iq = styled.div`
  grid-column: span 2;
  text-align: right;
`;

Community.LoadMore = styled(PageGridCenter)`
  text-align: center;
`;
