import React from 'react';
import {SizedImage} from '@/components';
import styled from 'styled-components';
import {overflowWithEllipsis} from '@/style';
import {propTypes} from './ChartAlbum.shared';
import {useEntity} from '@/hooks';

const ChartAlbum = ({itemId}) => {
  const album = useEntity('albums', itemId);
  return (
    <>
      <ChartAlbum.CoverAndTitle>
        <ChartAlbum.Cover>
          <SizedImage
            src={album.coverArtThumbnailUrl}
            width={60}
            height={60}
          />
        </ChartAlbum.Cover>
        <ChartAlbum.Title>{album.name}</ChartAlbum.Title>
      </ChartAlbum.CoverAndTitle>
      <ChartAlbum.Artist>{album.primaryArtistNames}</ChartAlbum.Artist>
    </>
  );
};

ChartAlbum.propTypes = propTypes;

export default React.memo(ChartAlbum);

ChartAlbum.CoverAndTitle = styled.div`
  grid-column: span 4;
  display: flex;
  align-items: center;
`;

ChartAlbum.Cover = styled.div`
  flex-shrink: 0;
`;

ChartAlbum.Title = styled.h3`
  margin-left: 30px;
  ${overflowWithEllipsis}
`;

ChartAlbum.Artist = styled.h4`
  grid-column: span 7;
  font-weight: 100;
  ${overflowWithEllipsis}
`;
