import React from 'react';
import styled from 'styled-components';
import {LoadMoreButton, DfpAd} from '@/components';
import {PageGrid, PageGridFull, PageGridCenter, ChartItem, ChartHeader} from '@/homePage/components';
import {shallowEqual, useSelector} from 'react-redux';
import {useMixpanelEvent, useDeviceType} from '@/hooks';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';

const Charts = () => {
  const {t} = useTranslation();
  const items = useSelector(state => get(state, 'home.chartSection.chartItems', []).map(({item}) => item), shallowEqual);
  const onChartItemClick = useMixpanelEvent('home:chart_item_clicked');
  const onLoadMoreClick = useMixpanelEvent('home:chart_more_loaded');
  const deviceType = useDeviceType({
    'mobile': 'mobile',
    'desktop': 'desktop',
  });

  return (
    <PageGrid>
      <ChartHeader
        title={t('charts.title')}
        adName={`${deviceType}_home_sponsored_charts`}
      />
      <PageGridFull>
        {items.map((item, index) => (
          (<React.Fragment key={item.id}>
            {
              index === 3 &&
                <DfpAd
                  name={`${deviceType}_home_charts_song`}
                  lazy={false}
                  refreshIntervalSeconds={null}
                />
            }
            <ChartItem item={item} rank={index + 1} onClick={onChartItemClick} />
          </React.Fragment>)
        ))}
      </PageGridFull>
      <LoadMoreButton path="home.chartSection" container={Charts.LoadMore} onClick={onLoadMoreClick} />
    </PageGrid>
  );
};

export default Charts;

Charts.LoadMore = styled(PageGridCenter)`
  text-align: center;
`;
