import React, {useMemo, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {chartFilterUpdated} from '@/homePage/actions';
import {SquareManySelects} from '@/components';
import pick from 'lodash/pick';
import filter from 'lodash/filter';
import {useTranslation} from 'react-i18next';

const ChartsFilter = () => {
  const {chartType, chartGenre, timePeriod} =  useSelector(state => state.home.chartSection);
  const dispatch = useDispatch();
  const onChartFilterChange = useCallback(filters => dispatch(chartFilterUpdated(filters)), [dispatch]);
  const {t} = useTranslation();
  const selectsForChart = useMemo(() => getSelectsForChart(chartType, t), [chartType, t]);
  const typeLabel = t(`charts.filter.header.type.${chartType}`);
  const songGenreLabel = chartType === 'songs' ? t(`charts.filter.header.genre.${chartGenre}`) : '';
  const timeSpanLabel = t(`charts.filter.header.time_period.${timePeriod}`);

  return (
    <SquareManySelects
      name={filter([typeLabel, songGenreLabel, timeSpanLabel]).join(' / ')}
      selects={selectsForChart}
      selected={{chartType, chartGenre, timePeriod}}
      onSelect={onChartFilterChange}
    />
  );
};

const getSelectsForChart = (chartType, t) => {
  const selectsForSongChart = {
    chartType: {
      name: t('charts.filter.select.type.name'),
      options: {
        songs: t('charts.filter.select.type.songs'),
        albums: t('charts.filter.select.type.albums'),
        artists: t('charts.filter.select.type.artists'),
        referents: t('charts.filter.select.type.referents'),
      },
    },
    chartGenre: {
      name: t('charts.filter.select.genre.name'),
      options: {
        all: t('charts.filter.select.genre.all'),
        rap: t('charts.filter.select.genre.rap'),
        pop: t('charts.filter.select.genre.pop'),
        rb: t('charts.filter.select.genre.rb'),
        rock: t('charts.filter.select.genre.rock'),
        country: t('charts.filter.select.genre.country'),
      },
    },
    timePeriod: {
      name: t('charts.filter.select.time_period.name'),
      options: {
        day: t('charts.filter.select.time_period.day'),
        week: t('charts.filter.select.time_period.week'),
        month: t('charts.filter.select.time_period.month'),
        all_time: t('charts.filter.select.time_period.all_time'),
      },
    },
  };

  return chartType === 'songs' ? selectsForSongChart : pick(selectsForSongChart, ['chartType', 'timePeriod']);
};

export default React.memo(ChartsFilter);
