import {useSelector} from 'react-redux';
import {useLoggedIn, useMixpanelEvent} from '@/hooks';

export const useRedux = () => {
  const leaderboard = useSelector(state => state.home.communitySection.leaderboard);
  const loggedIn = useLoggedIn();
  const onRankingClick = useMixpanelEvent('home:community_item_clicked');
  const onLearnMoreClick = useMixpanelEvent('home:community_cta_learn_more_clicked');
  const onSignupClick = useMixpanelEvent('home:community_cta_signup_clicked');
  const onGetInvolvedClick = useMixpanelEvent('home:community_cta_get_involved_clicked');

  return {
    leaderboard,
    loggedIn,
    onRankingClick,
    onLearnMoreClick,
    onSignupClick,
    onGetInvolvedClick,
  };
};
