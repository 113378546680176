import {produce} from 'immer';
import {
  LOAD_MORE_LOADED, LOAD_MORE_CLICKED, LOAD_MORE_FAILED,
  CHART_FILTER_UPDATED, CHART_FILTER_UPDATE_FAILED, CHART_FILTER_UPDATE_LOADED,
} from '@/homePage/actions';
import get from 'lodash/get';
import mergeWith from 'lodash/mergeWith';
import merge from 'lodash/merge';

const getPath = action => action.path.split('.').splice(1);

export default produce((draft, action) => {
  switch (action.type) {
  case LOAD_MORE_CLICKED:
    get(draft, getPath(action)).isLoading = true;
    break;
  case LOAD_MORE_FAILED:
    get(draft, getPath(action)).isLoading = false;
    break;
  case LOAD_MORE_LOADED: {
    const collection = get(draft, getPath(action));
    collection.isLoading = false;
    mergeWith(
      collection,
      action.result,
      (oldValue, newValue) =>
        Array.isArray(oldValue) && Array.isArray(newValue) ? oldValue.concat(newValue) : undefined
    );
    break;
  }
  case CHART_FILTER_UPDATED: {
    const {chartSection} = draft;
    const {updatedFilters} = action;
    merge(chartSection, updatedFilters);
    chartSection.isLoading = true;
    chartSection.chartItems = [];
    break;
  }
  case CHART_FILTER_UPDATE_FAILED: {
    draft.chartSection.isLoading = false;
    break;
  }
  case CHART_FILTER_UPDATE_LOADED: {
    const {chartSection} = draft;
    chartSection.isLoading = false;
    mergeWith(chartSection, action.result, (oldValue, newValue) =>
      Array.isArray(oldValue) && Array.isArray(newValue) ? newValue : undefined);
    break;
  }
  default:
    return draft;
  }
});
