import {useEntity, useMixpanelEvent} from '@/hooks';
import {useSelector} from 'react-redux';
import get from 'lodash/get';

export const useRedux = () => {
  const {
    videoLists = [],
    featuredVideoList,
  } = useSelector(state => get(state, 'home.videoSection'));
  const heroId = featuredVideoList;
  const heroVideoList = useEntity('videoLists', heroId);
  const heroVideo = useEntity('videos', heroVideoList.latestEpisode);
  const onPlacementClick = useMixpanelEvent('home:video_item_clicked');
  const onLoadMoreClick = useMixpanelEvent('home:video_more_loaded');

  return {
    heroId,
    heroLogo: heroVideoList.slug,
    heroLogoUrl: heroVideoList.url,
    heroImageUrl: heroVideo.posterUrl,
    heroUrl: heroVideo.url,
    videoListIds: videoLists,
    onPlacementClick,
    onLoadMoreClick,
  };
};
