import React from 'react';
import {SizedImage} from '@/components';
import styled from 'styled-components';
import {overflowWithEllipsis} from '@/style';
import {propTypes} from './ChartArtist.shared';
import {useEntity} from '@/hooks';

const ChartArtist = ({itemId}) => {
  const {imageUrl, name} = useEntity('artists', itemId);
  return (
    <ChartArtist.CoverAndName>
      <ChartArtist.Cover>
        <SizedImage
          src={imageUrl}
          width={60}
          height={60}
        />
      </ChartArtist.Cover>
      <ChartArtist.Name>{name}</ChartArtist.Name>
    </ChartArtist.CoverAndName>
  );
};

ChartArtist.propTypes = propTypes;

export default React.memo(ChartArtist);

ChartArtist.CoverAndName = styled.div`
  grid-column: span 11;
  display: flex;
  align-items: center;
`;

ChartArtist.Cover = styled.div`
  flex-shrink: 0;
`;

ChartArtist.Name = styled.h3`
  margin-left: 30px;
  ${overflowWithEllipsis}
`;
