import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import {ChartSong, ChartAlbum, ChartArtist, ChartReferent} from '@/homePage/components';

export const propTypes = {
  rank: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export const itemComponent = (schema) => {
  const component = `Chart${capitalize(schema.slice(0, -1))}`;
  return {ChartSong, ChartAlbum, ChartArtist, ChartReferent}[component];
};
