import {createLogic} from 'redux-logic';
import {LOAD_MORE_CLICKED, loadMoreLoaded, loadMoreFailed} from '@/homePage/actions';
import {getChartItems, getVideoLists, getLatestEditorialPlacements, getCommunityLeaderboard} from '@/api';
import get from 'lodash/get';
import bugsnag from '@/bugsnag';

const loadMoreByPath = (path, {perPage, nextPage, timePeriod, chartType, chartGenre}) => {
  switch (path) {
  case 'home.chartSection':
    return getChartItems({perPage, page: nextPage, timePeriod, chartType, chartGenre});
  case 'home.videoSection':
    return getVideoLists({perPage, page: nextPage, excludeFeatured: true});
  case 'home.latestSection':
    return getLatestEditorialPlacements({perPage, page: nextPage});
  case 'home.communitySection':
    return getCommunityLeaderboard({perPage, page: nextPage});
  }
};

const loadMoreLogic = createLogic({
  type: LOAD_MORE_CLICKED,
  process: ({getState, action: {path}}, dispatch, done) => {
    loadMoreByPath(path, get(getState(), path)).
      then(response => dispatch(loadMoreLoaded(path, response))).
      catch((e) => {
        bugsnag.notify(e);
        dispatch(loadMoreFailed(path));
      }).
      then(done);
  },
});

export default [
  loadMoreLogic,
];
